import { useEffect } from "react";
import { AxiosError } from "axios";
import { useRouter } from "next/navigation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { toast } from "react-hot-toast/headless";

import { getSession, removeSession } from "@/helpers/sessionToken";
import { useGetAxiosRequests } from "@/config/axios";
import { UpdateUserValues, UpdatePasswordValues } from "@/components/privatePages/Profile/schemas";
import { IUser, QueryKeys, Routes } from "@/types";

export const useGetUserQuery = () => {
  const router = useRouter();
  const client = useQueryClient();
  const { getUser } = useGetAxiosRequests();

  const query = useQuery<IUser>({
    queryKey: [QueryKeys.USER],
    queryFn: getUser,
    enabled: !!getSession()?.access_token,
  });

  useEffect(() => {
    if (query.isError) {
      if (query.error.status === 401 && query.error.message.match(/wrong/)) {
        removeSession();
        // queryClient.clear();
        client.setQueryData([QueryKeys.USER], () => null);
        // TODO: check if the page is public and replace only if it's private
        router.replace(Routes.HOME);
      }
    }
  }, [query.error, query.isError, client, router]);

  return query;
};

export const useUpdateUserMutation = () => {
  const { updateUser } = useGetAxiosRequests();
  const client = useQueryClient();

  return useMutation<unknown, AxiosError<any>, UpdateUserValues>({
    mutationFn: updateUser,
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [QueryKeys.USER] });
      toast.success("Updated your Profile");
    },
    onError: error => {
      toast.error(error.response?.data.message ?? error.message);
    },
  });
};

export const useUpdatePasswordMutation = () => {
  const { updatePassword } = useGetAxiosRequests();

  return useMutation<unknown, AxiosError<any>, UpdatePasswordValues>({
    mutationFn: updatePassword,
    onSuccess: async () => {
      toast.success("Password changed successfully");
    },
    onError: error => {
      toast.error(error.response?.data.message ?? error.message);
      console.error(error);
    },
  });
};
